import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Auth } from '@aws-amplify/auth';
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { Button, Row, Col } from "reactstrap";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { CSVLink } from "react-csv";
import { service } from './service'
import './style.css'

const Home = ({ signOut }) => {
  const params = new URLSearchParams(window.location.search)
  const initialPageList = params.has('rows') ? params.get('rows') : 10;
  const [jwToken, setJwToken] = useState()
  const [promoterData, setPromoterData] = useState()
  const [noOfItems, setNoOfItems] = useState(initialPageList)
  const [noOfItemsOptions, setNoOfItemsOptions] = useState()
  const [promoterFields, setPromoterFields] = useState([])
  const [eventNameOptions, setEventNameOptions] = useState([])
  const [eventSessionOptions, setEventSessionOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [landingFormSubmit, setLandingFormSubmit] = useState(params.has('rows') ? true : false)
  const [loadingMessage, setLoadingMessage] = useState('Loading...')
  const [eventName, setEventName] = useState()
  const [eventSession, setEventSession] = useState()
  const [submitError, setSubmitError] = useState(false)

  let promoterList = {}
  let eventList = {}
  let promoterExportData = []

  // Auth.currentSession().then(data => setJwToken(data.getAccessToken().getJwtToken()));

  useEffect(() => {

    landingFormSubmit && fetchPromoterData()
    !landingFormSubmit && fetchEvents()
  }, [landingFormSubmit]);

  useEffect(() => {

    eventName ? fetchEventSessions() : setEventSessionOptions([])
  }, [eventName]);

  const fetchEvents = async () => {
    const eventList = await service.fetchEvents();
    setEventNameOptions(eventList?.eventNames.map((event, index) => {
      return <option value={event} key={index + event}>{event}</option>
    }));
  }

  const fetchEventSessions = async () => {
    const eventSessionList = await service.fetchEventSessions(eventName);
    setEventSessionOptions(eventSessionList?.rows.map(({ session_id, session }) => {
      return <option value={session_id} key={session_id}>{session}</option>
    }));
  }

  const fetchPromoterData = async () => {
    // if (jwToken) {
    let promoterResponse = {
      rows: [],
      fields: []
    }
    const batchSize = 500;
    const totalPromoters = await service.fetchPromotersCount(jwToken, eventName, eventSession);
    const totalBatches = Math.ceil(totalPromoters / batchSize);

    for (let i = 0; i < totalBatches; i++) {
      setLoadingMessage(`Loading Batch ${i + 1} of ${totalBatches}`)
      const offset = i * batchSize;
      const limit = batchSize;
      const promoters = await service.fetchPromoters(jwToken, limit, offset, eventName, eventSession);
      if (promoters.success === true) {
        if (promoterResponse.fields.length == 0) {
          promoterResponse.fields = promoters.fields
        }
        promoterResponse.rows = promoterResponse.rows.concat(promoters.rows)
        if (i + 1 == totalBatches) {
          allocatePromoterData(promoterResponse)
        }
      } else {
        // fail silently
      }

    }
    // }
  }

  const allocatePromoterData = (response) => {
    setPromoterData(response.rows);
    promoterExportData = response.rows
    const options = new Array(response.rows.length).fill().map((_, page) => {
      page += 1;
      if (page === 1) {
        return <option value={10} key={10}>{10}</option>;
      } else {
        return page % 100 === 0 ? (
          <option value={page} key={page}>
            {page}
          </option>
        ) : null;
      }
    });
    setNoOfItemsOptions(options);


    const uniquePromoters = [...new Set(response.rows.map(d => d.promoter_name))];
    const uniqueEvents = [...new Set(response.rows.map(d => d.event_name))];

    uniquePromoters.forEach(element => {
      promoterList = {
        ...promoterList,
        ...{ [element]: element },
      }
    });

    uniqueEvents.forEach(element => {
      eventList = {
        ...eventList,
        ...{ [element]: element },
      }
    });

    const columnTitles = response.fields.map((item) => {
      return {
        dataField: item.name,
        text: item.name.replace(/_/i, ' ').toUpperCase(),
        filter: item.name == 'promoter_name' || item.name == 'event_name' ? selectFilter({
          options: item.name == 'promoter_name' ? promoterList : eventList
        }) : textFilter(),
        classes: "border-0 align-middle",
        headerClasses: "border-0",
        sort: true,
      }
    })
    setPromoterFields([
      ...promoterFields,
      ...columnTitles
    ]);
    setIsLoading(false)
  }

  const afterFilter = (newResult) => {
    promoterExportData = newResult
  }

  const getPromoterExportData = () => {
    // Remove unwanted fields from the data
    const exportData = promoterExportData.length > 0 ? promoterExportData : promoterData
    const filteredData = exportData.map(({ promoter_name, created_on, id, ...item }) => item);
    return filteredData
  }

  const options = {
    custom: true,
    sizePerPage: noOfItems,
    totalSize: promoterData ? promoterData.length : 0,
  };

  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };
  const handleFirstPage = ({ page, onPageChange }) => () => {
    onPageChange(1);
  };

  const handleLastPage = ({ sizePerPage, onPageChange, totalSize }) => () => {
    onPageChange(Math.ceil(totalSize / sizePerPage));
  };
  const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
    <span>
      {(page - 1) * sizePerPage + 1} to{" "}
      {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize}
    </span>
  );

  const handleChange = (item) => {
    const { name, value } = item.target;
    if (name == 'eventName') {
      setSubmitError(false)
      setEventName(value)
      setEventSession()
    } else {
      name == 'sessionId' && setEventSession(value)
    }
  }

  return (
    <>
      {landingFormSubmit ? <>

        {promoterData && !isLoading ? <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => {
            const lastIndex =
              paginationProps.page * paginationProps.sizePerPage;

            return (
              <Fragment>
                <div className="logout-btn-wrapper">
                  <button onClick={() => {
                    window.location.href = '/'
                  }}>Home</button>
                  {promoterExportData && <CSVLink data={getPromoterExportData()}
                    filename={"promoter-data.csv"} className="export-csv">Export</CSVLink>}
                  <button onClick={() => {
                    localStorage.clear();
                    setTimeout(() => {
                      window.location.reload()
                    }, 1000);
                  }}>Logout</button>
                </div>
                <div className="table-responsive">

                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={promoterData.map((item, index) => {
                      return {
                        ...item, id: index + Date.now()
                      }
                    })}
                    columns={promoterFields}
                    bordered={true}
                    condensed={true}
                    hover={true}
                    tabIndexCell={true}
                    striped={true}
                    expanded={true}
                    expandable={true}
                    searchable={true}
                    filter={filterFactory({ afterFilter })}
                    classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                    rowClasses="btn-reveal-trigger border-top border-200"
                    headerClasses="bg-200 text-900 border-y border-200"
                    {...paginationTableProps}
                  />
                </div>
                <Row className="px-1 py-3">
                  <Col className="pl-3 fs--1">
                    <CustomTotal
                      {...paginationProps}
                      lastIndex={lastIndex}
                    />
                    <select className="item-per-page" onChange={(item, index) => {
                      setNoOfItems(item.target.value)
                      // window.location.href = `/?rows=${item.target.value}`  // work around of hang issue
                    }}>
                      {noOfItemsOptions}
                    </select>
                  </Col>
                  <Col xs="auto" className="pr-1">
                    <Button
                      color={
                        paginationProps.page === 1
                          ? "light"
                          : "primary"
                      }
                      size="sm"
                      onClick={handleFirstPage(paginationProps)}
                      disabled={paginationProps.page === 1}
                      className="px-4 ml-2 pagination-button"
                    >
                      First
                    </Button>

                    <Button
                      color={
                        paginationProps.page === 1
                          ? "light"
                          : "primary"
                      }
                      size="sm"
                      onClick={handlePrevPage(paginationProps)}
                      disabled={paginationProps.page === 1}
                      className="px-4 ml-2 pagination-button"
                    >
                      Previous
                    </Button>
                    <Button
                      color={
                        lastIndex >= paginationProps.totalSize
                          ? "light"
                          : "primary"
                      }
                      size="sm"
                      onClick={handleNextPage(paginationProps)}
                      disabled={
                        lastIndex >= paginationProps.totalSize
                      }
                      className="px-4 ml-2 pagination-button"
                    >
                      Next{" "}
                    </Button>
                    <Button
                      color={
                        lastIndex >= paginationProps.totalSize
                          ? "light"
                          : "primary"
                      }
                      size="sm"
                      onClick={handleLastPage(paginationProps)}
                      disabled={
                        lastIndex >= paginationProps.totalSize
                      }
                      className="px-4 ml-2 pagination-button"
                    >
                      Last
                    </Button>

                  </Col>
                </Row>
              </Fragment>
            );
          }}
        </PaginationProvider> : !promoterData && !isLoading ? <div className="exception-msg">No datas found</div> :
          <div className="exception-msg">{loadingMessage}</div>}
      </> :
        <>
          <div className="logout-btn-wrapper">
            <button onClick={() => {
              localStorage.clear();
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }}>Logout</button>
          </div>
          <div className="landing-wrapper">
            <div className="landing-form-head">
              <h4>PROMOTER DATA</h4>
              <span>What data would you like to load?</span>
            </div>
            <div className="landing-form-body">
              <div className="everything-section">
                <button onClick={() => {
                  setEventName()
                  setEventSession()
                  setLandingFormSubmit(true)
                }}>Everything</button>
              </div>

              <div className="divider">
                <span className="divider-line"></span>
                <span className="divider-text">OR</span>
                <span className="divider-line"></span>
              </div>


              <div className="filter-section">
                <div>
                  <label htmlFor="dropdown">EVENT</label>
                  <select id="dropdown" name="eventName" onChange={handleChange}>
                    <option value="">Select Event</option>
                    {eventNameOptions}
                  </select>
                  {submitError && <span style={{ color: 'red' }}>Event required!</span>}
                </div>

                <div className="session-section">
                  <label htmlFor="dropdown">SESSION</label>
                  <select id="dropdown" name="sessionId" onChange={handleChange}>
                    <option value="">Select Session</option>
                    {eventSessionOptions}
                  </select>
                </div>
                <div className="session-section everything-section">
                  <button onClick={() => {
                    if (!eventName) {
                      setSubmitError(true)
                    } else {
                      setLandingFormSubmit(true)
                    }

                  }}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Home;