import './style.css'

const Register = () => {
  return (
    <>
      <div className="container">
        <div className='wrapper'>
          <div className='inner-wrapper'>
            <div className='logo-section'>
              <h1 className='label'>Sign Up</h1>
            </div>
            <div className='credential-section'>
              <div className='item-wrapper'>
                <input type='text' name="userfullname" className='item' placeholder='Name'></input>
              </div>
              <div className='item-wrapper'>
                <input type='email' name="useremail" className='item' placeholder='Email Id'></input>
              </div>
              <div className='item-wrapper'>
                <input type='text' name="username" className='item' placeholder='User name'></input>
              </div>
              <div className='item-wrapper'>
                <input type='password' name="password" className='item' placeholder='Password'></input>
              </div>

              <div className='item-wrapper'>
                <input type='button' name="password" className='item submit' value='Sign Up'></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;