import './style.css'

const Login = () => {
    return (
        <>
            <div className="container">
                <div className='wrapper'>
                    <div className='inner-wrapper'>
                        <div className='logo-section'>
                            <h1 className='label'>Sign In</h1>
                        </div>
                        <div className='credential-section'>
                            <div className='item-wrapper'>
                                <input type='text' name="username" className='item' placeholder='User name'></input>
                            </div>
                            <div className='item-wrapper'>
                                <input type='password' name="password" className='item' placeholder='Password'></input>
                            </div>
                            <div className='item-wrapper'>
                                <input type='button' name="password" className='item submit' value='Sign In'></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;