import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import Login from '../Components/Auth/Login/Login'
import Register from '../Components/Auth/Register/Register'
import Home from '../Components/Home/Home'

const Layout = ({signOut}) => {
    return (
        <>
            <Header />
            <Router>
                <Routes>
                    <Route path='/' element={<Home signOut={signOut} />} />
                    <Route path='/signin' element={<Login />} />
                    <Route path='/signup' element={<Register />} />
                </Routes>
            </Router>
            <Footer />
        </>

    )
}

export default Layout