import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Layout from './Router/Routes';

function App({ signOut }) {
  
  return (
    <Layout signOut={signOut} />
  );
}

export default withAuthenticator(App);