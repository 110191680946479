const axios = require("axios");

const fetchPromotersCount = async (token, eventName, eventSession) => {
    const customConfig = {
        headers: {
            "token": token
        },
        params: {
            event_name: eventName,
            session_id: eventSession
        }
    };

    return axios
        .get(process.env.REACT_APP_API_URL + "promoters/count", customConfig)
        .then((response) => {
            return response.data.rows[0].count;
        })
        .catch((error) => {
            return [];
        });
}

const fetchPromoters = async (token, limit, offset, eventName, eventSession) => {
    const customConfig = {
        headers: {
            "token": token
        },
        params: {
            limit,
            offset,
            event_name: eventName,
            session_id: eventSession
        }
    };

    return axios
        .get(process.env.REACT_APP_API_URL + "promoters/", customConfig)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return [];
        });
}

const fetchEventSessions = async (event) => {

    const customConfig = {
        params: {
            event_name: event
        }
    };

    return axios
        .get(process.env.REACT_APP_API_URL + "promoters/event/session", customConfig)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
}

const fetchEvents = async () => {

    return axios
        .get(process.env.REACT_APP_API_URL + "promoters/event/list")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
}

export const service = {
    fetchPromoters,
    fetchPromotersCount,
    fetchEvents,
    fetchEventSessions
}